/* Begin of About us css  */
.heading-inner-banner h5 {
	font-size: 12px;
	color: #000000;
	font-family: "Poppins", sans-serif;
}
span.phone2 {
	margin-left: 20px;
}
.heading-inner-banner h5 span {
	color: #ee2238;
	text-transform: uppercase;
}
.heading-inner-banner h2 {
	font-size: 35px;
	color: #000000;
	font-family: "Poppins", sans-serif;
	font-weight: 600;
}
.heading-inner-banner {
	text-align: center;
}
.rasani-ul-and-button {
	display: flex;
	justify-content: space-between;
	align-items: end;
}

section.aboutus-sec {
	padding: 70px 0px;
}

.line-head h5 {
	font-size: 16px;
	color: #ee2238;
	font-family: "Poppins", sans-serif;
	font-weight: 600;
}
.line-head {
	display: flex;
	align-items: center;
}
.line-head h5 {
	font-size: 16px;
	color: #ee2238;
	font-family: "Poppins", sans-serif;
	font-weight: 600;
	padding-left: 18px;
	padding-top: 8px;
}

.rasani-head1 h2 {
	font-size: 30px;
	color: #000000;
	font-family: "Poppins", sans-serif;
	font-weight: 700;
}
.rasani-head h2 {
	font-size: 35px;
	color: #000000;
	font-family: "Poppins", sans-serif;
	font-weight: 700;
}
.rasani-para1 p {
	font-size: 16px;
	color: #000000;
	font-family: Poppins-regular;
	font-weight: 400;
	margin-top: 20px;
	line-height: 2;
	text-transform: capitalize;
}
.rasani-para p {
	font-size: 15px;
	color: #000000;
	font-family: Poppins-regular;
	font-weight: 400;
	margin-top: 20px;
	line-height: 2;
}

.rasani-ul-and-button ul {
	padding: 0px 25px;
	list-style: none;
}

section.how-rasani-sec {
	padding: 0px 0px 50px 0px;
}
section.consulting-sec {
	padding: 0px 0px 80px 0px;
}
.rasani-ul-and-button ul li::before {
	content: url("../img/check.webp");
	position: absolute;
	left: 14px;
	margin-top: 3px;
}
.rasani-ul-and-button ul li {
	font-size: 13px;
	color: #000000;
	font-family: "Poppins-regular";
	line-height: 2;
	margin-left: 10px;
	text-transform: capitalize;
}
.rasani-button {
	border: 1px solid #ee2238;
	padding: 15px 59px;
	background-color: #ee2238;
	border-radius: 0;
	margin-bottom: 20px;
	cursor: pointer;
}
.rasani-button a {
	text-decoration: none;
	font-family: Poppins-SemiBold;
	font-size: 16px;
	color: #ffff;
}
.rasani-button:hover {
	border: 1px solid #ee2238;
	background-color: transparent;
}
.rasani-button:hover a {
	color: #ee2238;
	text-decoration: none;
}

section.market-place-sec {
	padding-top: 100px;
	background: #fbfcfd;
	padding-bottom: 100px;
}
.rasani-head {
	margin: 50px 320px 0px 220px;
}

.rasani-para {
	margin: 20px 320px 0px 220px;
}
.backgrounddg {
	background: #fff;
}
.market-img {
	position: absolute;
	top: 84px;
	right: -351px;
}
.market-img img {
	height: 100%;
	width: 91%;
}
@media (max-width: 1440px) {
	.rasani-head h2 {
		font-size: 28px;
	}
	.rasani-para p {
		font-size: 15px;
	}
	.rasani-head {
		margin: 50px 320px 0px 140px;
	}
	.rasani-para {
		margin: 20px 320px 0px 140px;
	}
	.market-img {
		top: 54px;
		right: -381px;
	}
}
@media (max-width: 1280px) {
	.rasani-para p {
		font-size: 13px;
	}
}
@media (max-width: 1024px) {
	.rasani-para {
		margin: 20px 320px 0px 40px;
	}
	.rasani-head {
		margin: 40px 320px 0px 40px;
	}
	.rasani-head h2 {
		font-size: 21px;
	}
	.market-img {
		top: 54px;
		right: -351px;
	}
}
@media (max-width: 575px) {
	.heading-inner-banner h2 {
		font-size: 25px;
	}
	section.how-rasani-sec h2 {
		font-size: 22px;
		color: #000000;
		font-family: "Poppins", sans-serif;
		font-weight: 700;
	}
	section.how-rasani-sec .rasani-para1 {
		padding: 0 15px;
	}
	.how-rasani-sec .rasani-head1 {
		display: flex;
		align-items: center;
	}
	.how-rasani-sec .rasani-head1 h5 {
		color: #ee2238;
		font-family: Poppins, sans-serif;
		font-size: 16px;
		font-weight: 600;
		padding-left: 18px;
		padding-top: 8px;
	}
	.rasani-head1 h2 {
		font-size: 22px;
	}
	.rasani-ul-and-button {
		display: block;
	}
	.rasani-button {
		margin-bottom: 20px;
		width: 100%;
		text-align: center;
	}
	section.consulting-sec {
		padding: 0px 0px 10px 0px;
	}
	.rasani-para1 p {
		font-size: 14px;
	}
	.lead-buyind-para h2 {
		font-size: 26px !important;
	}
	.lead-buyind-para h4 {
		font-size: 26px !important;
	}
	.lead-buyind-para p {
		font-size: 14px;
	}
	section.get-started-sec {
		margin-top: 70px;
	}
	.get-started-text h2 {
		font-size: 22px;
	}
}
@media (max-width: 375px) {
	.rasani-ul-and-button {
		display: block;
	}
	.rasani-button {
		margin-bottom: 20px;
		width: 100%;
		text-align: center;
	}
	section.consulting-sec {
		padding: 0px 0px 10px 0px;
	}
	.rasani-para1 p {
		font-size: 12px;
	}
	.lead-buyind-para h2 {
		font-size: 22px;
	}
	.lead-buyind-para p {
		font-size: 14px;
	}
	section.get-started-sec {
		margin-top: 70px;
	}
	.get-started-text h2 {
		font-size: 22px;
	}
}
/* End of About us css  */
