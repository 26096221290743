.access-para p {
	font-size: 16px !important;
	padding-top: 20px;
	font-family: poppins-regular;
	text-transform: capitalize;
}
@media (max-width: 575px) {
	.access-para p {
		font-size: 13px !important;
	}
	.how-rasani-sec .rasani-head1 {
		display: block;
	}
	section.how-rasani-sec .rasani-para1 {
		padding: 0 15px;
	}
}
