.text-box {
	overflow: auto;
}
.privacy-things h2 {
	font-size: 35px;
	color: #000000;
	font-family: "Poppins";
	font-weight: 700;
}
.privacy-policy-sec p {
	font-size: 16px;
	color: #000000;
	font-family: Poppins-regular;
	line-height: 2;
	text-transform: capitalize;
}
.privacy-policy-sec span {
	color: #ee2238;
	font-size: 16px;
	font-family: poppins-medium;
}
.privacy-h3-and-ul h4 {
	font-size: 25px;
	color: #000000;
	font-family: Poppins-bold;
}
.https {
	color: #ee2238;
	font-size: 14px;
	font-family: poppins-regular;
}
.cookies {
	color: #ee2238;
	font-size: 14px;
	font-family: poppins-regular;
}
@media (max-width: 575px) {
	.privacy-policy-sec p {
		font-size: 13px;
	}
	.privacy-things h2 {
		font-size: 23px;
	}
	.privacy-things ul li {
		font-size: 14px;
	}
	.privacy-things p {
		font-size: 14px;
	}
	.https {
		color: #ee2238;
		font-size: 14px;
		font-family: poppins-regular;
		overflow-wrap: break-word;
	}
}
