section.Affilate-sec {
	margin: 70px 0;
}
.affilate-sec {
	background-image: linear-gradient(#f3f6fb, #fff, #fff, #fff) !important;
	padding: 140px;
}

.get-started-text h2 {
	font-size: 40px;
	color: #ffffff;
	font-family: "Poppins";
	font-weight: 700;
	padding-bottom: 18px;
}

.create-account-btn button {
	background-color: #000000;
	color: #ffffff;
	padding: 10px 40px;
	font-size: 14px;
	font-family: "Poppins";
	font-weight: 600;
	border: unset;
}
.create-account-btn button a {
	text-decoration: none;
	color: #ffffff;
}
.get-started-text {
	text-align: center;
}

section.get-started-sec {
	background-color: #ee2238;
	padding: 60px;
	box-shadow: 0px -17px 0px 2px rgb(202 138 144 / 26%);
	-webkit-box-shadow: 0px -17px 0px 2px hwb(354deg 13% 7% / 16%);
	-moz-box-shadow: 0px -17px 0px 2px rgba(238, 34, 56, 0.26);
}
.affilate-text p {
	font-size: 14px;
	color: #000000;
	font-family: "Poppins-regular";
	font-weight: 400;
	line-height: 2;
	padding-right: 18px;
	text-transform: capitalize;
}
.affilte-ul ul li {
	font-size: 13px;
	line-height: 37px;
	font-family: "Poppins";
	font-weight: 400;
	text-transform: capitalize;
}
.right-buyer h4 {
	font-size: 30px;
	color: #000000;
	font-family: Poppins-bold;
	margin-bottom: 30px;
}
.right-buyer p {
	font-size: 16px;
	line-height: 25px;
	font-family: "Poppins";
	font-weight: 400;
	margin-bottom: 30px;
	padding-right: 17px;
	text-transform: capitalize;
}
.right-buyer {
	margin-bottom: 110px;
}
@media (max-width: 800px) {
	.affilate-sec {
		padding: 20px;
		margin-bottom: 50px;
	}
}
@media (max-width: 575px) {
	.right-buyer h4 {
		font-size: 22px;
	}
	.right-buyer p {
		font-size: 14px;
	}
	.affilate-sec {
		padding: 20px;
		margin-bottom: 50px;
	}
	.get-started-text h2 {
		font-size: 22px;
	}
}
@media (max-width: 414px) {
	.right-buyer h4 {
		font-size: 22px;
	}
	.right-buyer p {
		font-size: 14px;
	}
	.affilate-sec {
		padding: 0;
		margin-bottom: 50px;
	}
	.get-started-text h2 {
		font-size: 22px;
	}
}
@media (max-width: 375px) {
	.right-buyer h4 {
		font-size: 22px;
	}
	.right-buyer p {
		font-size: 14px;
	}
	.affilate-sec {
		padding: 0;
	}
	.get-started-text h2 {
		font-size: 22px;
	}
}
