@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Poppins:wght@200&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/* font family includes */
@font-face {
	src: url(../fonts/DMSans-Bold.ttf);
	font-family: "DMSans-Bold";
}

@font-face {
	src: url(../fonts/DMSans-Medium.ttf);
	font-family: "DMSans-Medium";
}

@font-face {
	src: url(../fonts/DMSans-Regular.ttf);
	font-family: "DMSans-Regular";
}

@font-face {
	src: url(../fonts/Poppins-Medium.ttf);
	font-family: "Poppins-Medium";
}

@font-face {
	src: url(../fonts/Poppins-Light.ttf);
	font-family: "Poppins-Light";
}

@font-face {
	src: url(../fonts/Poppins-SemiBold.ttf);
	font-family: "Poppins-SemiBold";
}

@font-face {
	src: url(../fonts/Poppins-Bold.ttf);
	font-family: "Poppins-Bold";
}

@font-face {
	src: url(../fonts/Poppins-Regular.ttf);
	font-family: "Poppins-Regular";
}

@font-face {
	src: url(../fonts/Poppins-Regular.ttf);
	font-family: "Poppins-Regular";
}

@font-face {
	src: url(../fonts/Aileron-Bold.otf);
	font-family: "Aileron-Bold";
}

@font-face {
	src: url(../fonts/Aileron-SemiBold.otf);
	font-family: "Aileron-SemiBold";
}

@font-face {
	src: url(../fonts/Aileron-Regular.otf);
	font-family: "Aileron-Regular";
}

/* @keyframes  digital {
    0%{right: 20px;}
  25% {left: 40px;}
  
    
  } */
@keyframes bounce {

	0%,
	100%,
	20%,
	50%,
	80% {
		-webkit-transform: translateY(0);
		-ms-transform: translateY(0);
		transform: translateY(0);
	}

	40% {
		-webkit-transform: translateY(-30px);
		-ms-transform: translateY(-30px);
		transform: translateY(-30px);
	}

	60% {
		-webkit-transform: translateY(-15px);
		-ms-transform: translateY(-15px);
		transform: translateY(-15px);
	}
}

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

body {
	overflow-x: hidden !important;
}

html {
	overflow-x: hidden !important;
}

body {
	background-color: #ffffff;
}

.digital {
	animation: digital 5s ease-in-out infinite;
}

/* navbar start here */
.bg-white {
	background-image: linear-gradient(#f3f6fb, #ffffff) !important;
	height: 108px;
}

header#header-top {
	background-color: #f8f8f8;
}

.logo-main {
	margin-left: -10px;
}

header#header-top .nav-bar .navbar {
	list-style: none;
}

header#header-top .nav-bar .navbar li a {
	font-family: "Poppins-SemiBold";
	font-size: 13px;
	text-decoration: none;
	color: #000000;
}

header#header-top .nav-bar .navbar li a:hover {
	color: #ee2238 !important;
	transition: 0.4s;
}

/* banner start here */
.Banner {
	background: #f8f8f8;
	padding-bottom: 50px;
}

.banner-content {
	margin-top: 100px;
}

.Banner .banner-content .about-content {
	display: flex;
	margin-bottom: 30px;
}

.Banner .banner-content .about-content h6 {
	line-height: 0;
	margin-left: 20px;
	font-family: Poppins-SemiBold;
	font-size: 16px;
	color: #ee2238;
}

.Banner .banner-content .about-content img {
	height: 3px;
}

.Banner .banner-content .heading-para h2 {
	font-family: Poppins-Bold;
	font-size: 65px;
	color: #000000;
	margin-bottom: 30px;
}

.Banner .banner-content .heading-para p {
	color: #7c7c7c;
	font-family: "Poppins-Regular";
	font-size: 16px;
	line-height: 2;
	text-transform: capitalize;
}

.Banner .banner-content .banner-btn {
	margin-top: 41px;
}

.Banner .banner-content .banner-btn .btn {
	border: 1px solid #ee2238;
	padding: 15px 86px;
	background-color: #ee2238;
	border-radius: 0;
}

.Banner .banner-content .banner-btn .btn a {
	text-decoration: none;
	font-family: Poppins-SemiBold;
	font-size: 16px;
	color: #ffff;
}

.Banner .banner-content .banner-btn .btn:hover {
	border: 1px solid #ee2238;
	background-color: transparent;
}

.Banner .banner-content .banner-btn .btn:hover a {
	color: #ee2238;
}

.Banner .banner-img-erapper {
	text-align: center;
}

.Banner .banner-img-erapper .manycheckbox {
	display: flex;
	justify-content: center;
	position: relative;
}

.Banner .banner-img-erapper .manycheckbox .live {
	position: absolute;
	left: 0;
	top: -331px;
}

.Banner .banner-img-erapper .manycheckbox button.livebtn {
	display: flex;
	align-items: center;
	margin-left: 20px;
	border: 1px solid #000000;
	padding: 10px 26px;
	background-color: #000000;
	border-radius: 0;
}

.Banner .banner-img-erapper .manycheckbox button.livebtn:hover {
	transform: translateY(-10px);
	cursor: pointer;
	transition: 0.5s;
}

.Banner .banner-img-erapper .manycheckbox button.livebtn h5 {
	color: #ffffff;
	font-family: "Poppins-Regular";
	font-size: 14px;
	margin-top: 7px;
}

.Banner .banner-img-erapper .manycheckbox button.livebtn img {
	margin-right: 15px;
}

.Banner .banner-img-erapper .manycheckbox .data {
	position: absolute;
	right: 0px;
	top: -198px;
}

.Banner .banner-img-erapper .manycheckbox button.databtn {
	display: flex;
	align-items: center;
	margin-left: 20px;
	border: 1px solid #000000;
	padding: 10px 26px;
	background-color: #000000;
	border-radius: 0;
}

.Banner .banner-img-erapper .manycheckbox button.databtn:hover {
	transform: translateY(-10px);
	cursor: pointer;
	transition: 0.5s;
}

.Banner .banner-img-erapper .manycheckbox button.databtn h5 {
	color: #ffffff;
	font-family: "Poppins-Regular";
	font-size: 14px;
	margin-top: 7px;
}

.Banner .banner-img-erapper .manycheckbox button.databtn img {
	margin-right: 15px;
}

.Banner .banner-img-erapper .manycheckbox .digital {
	position: absolute;
	right: 20px;
	top: -520px;
}

.Banner .banner-img-erapper .manycheckbox button.digitalbtn {
	display: flex;
	align-items: center;
	margin-left: 20px;
	border: 1px solid #000000;
	padding: 10px 26px;
	background-color: #000000;
	border-radius: 0;
}

.Banner .banner-img-erapper .manycheckbox button.digitalbtn:hover {
	transform: translateY(-10px);
	cursor: pointer;
	transition: 0.5s;
}

.Banner .banner-img-erapper .manycheckbox button.digitalbtn h5 {
	color: #ffffff;
	font-family: "Poppins-Regular";
	font-size: 14px;
	margin-top: 7px;
}

.Banner .banner-img-erapper .manycheckbox button.digitalbtn img {
	margin-right: 15px;
}

/* lead-sec */
section.lead {
	background: #ee2238;
	height: 100%;
}

.lead-wrapper {
	margin-top: -15px;
}

.circle-content {
	text-align: center;
}

.circle-content h4 {
	padding: 50px 0;
	border: 4px dotted #ffffff;
	width: 142px;
	height: 140px;
	border-radius: 100px;
	font-family: Poppins-Bold;
	font-size: 31px;
	color: #ffffff;
	margin: 0 auto;
}

.content-para {
	margin-top: 32px;
}

.leadmargin {
	margin-top: 50px;
	margin-bottom: 50px;
}

.content-para h4 {
	font-family: Poppins-Bold;
	font-size: 24px;
	color: #ffffff;
	white-space: nowrap;
}

.content-para p {
	font-family: Poppins-Regular;
	font-size: 13px;
	color: #ffffff;
	line-height: 30px;
	text-transform: capitalize;
	text-align: center;
}

/* third sec  start*/
.thirdsec {
	margin-top: 100px;
}

section.thirdsec .thirdsec-content .about-content {
	display: flex;
	margin-bottom: 40px;
	margin-top: 10px;
}

section.thirdsec .thirdsec-content .about-content h6 {
	line-height: 0;
	margin-left: 20px;
	font-family: Poppins-SemiBold;
	font-size: 16px;
	color: #ee2238;
}

section.thirdsec .thirdsec-content .about-content img {
	height: 3px;
}

section.thirdsec .thirdsec-content .heading-para h2 {
	font-family: Poppins-Bold;
	font-size: 35px;
	color: #000000;
	margin-bottom: 30px;
}

section.thirdsec .thirdsec-content .heading-para p {
	color: #7c7c7c;
	font-family: Poppins-Regular;
	font-size: 16px;
	line-height: 2;
	text-transform: capitalize;
}

section.thirdsec .thirdsec-content .banner-btn {
	margin-top: 41px;
}

section.thirdsec .thirdsec-content .banner-btn .btn {
	border: 1px solid #ee2238;
	padding: 15px 75px;
	background-color: #ee2238;
	border-radius: 0;
}

section.thirdsec .thirdsec-content .banner-btn .btn a {
	text-decoration: none;
	font-family: Poppins-SemiBold;
	font-size: 16px;
	color: #ffff;
}

section.thirdsec .thirdsec-content .banner-btn .btn:hover {
	border: 1px solid #ee2238;
	background-color: transparent;
}

section.thirdsec .thirdsec-content .banner-btn .btn:hover a {
	color: #ee2238;
}

/* footer */
.logo-mainfooter {
	margin-left: 20px;
}

footer#footerdown {
	/* margin-top: 40px; */
	overflow-x: hidden;
}

.socialitems1 ul {
	display: flex;
	margin: 0px 18px;
}

.socialitems1 ul li {
	margin-left: 22px;
	list-style: none;
}

.socialitems1 {
	padding: 26px 0px;
}

.socialitems1 ul li a i {
	font-size: 20px;
	color: #ffffff;
}

.backgroundcolor {
	background-color: #ee2238;
}

.backgroundlr {
	background-color: #000000;
}

.para-social {
	padding-top: 20px;
	padding-left: 40px;
}

.para-social p {
	color: #ffffff;
	font-family: DMSans-Regular;
	font-size: 20px;
}

.socialitems {
	padding-top: 20px;
	position: relative;
	z-index: 1;
	top: 46px;
	padding: 7px;
	left: -15px;
}

.lastfooter {
	margin-bottom: 70px;
}

.footer-form {
	margin-left: 27px;
	margin-top: 30px;
}

.why-content {
	display: flex;
	margin-bottom: 40px;
	margin-top: 80px;
}

.why-content img {
	height: 3px;
}

.why-content h6 {
	line-height: 0;
	margin-left: 20px;
	font-family: Poppins-SemiBold;
	font-size: 16px;
	color: #ee2238;
}

.fromcontorl input {
	width: 100%;
	height: 50px;
	border: none;
	background: #f5f5f5;
	padding: 20px 29px;
}

.fromcontorl textarea {
	height: 100px;
	border: 0;
}

.footer-form span.errorMessage {
	font-size: 12px;
	color: red;
}

.footer-form .SetErrorshow {
	border: 1px solid red !important;
}

.fromcontorl input::placeholder {
	font-size: 15px;
	font-family: Poppins-Regular;
	color: #808080;
}

.fromcontorl input:focus {
	border-color: #ee2238 !important;
}

.fromcontorl {
	margin: 10px 0px;
}

.texterea {
	margin-top: 20px;
}

.form-group textarea#exampleFormControlTextarea1 {
	width: 100%;
	border: none;
	background: #f5f5f5;
	resize: none;
	padding: 20px 30px;
	height: 150px;
}

.img-footer img {
	height: 630px;
	object-fit: cover;
}

.form-group textarea#exampleFormControlTextarea1::placeholder {
	font-size: 15px;
	font-family: Poppins-Regular;
	color: #808080;
}

.heading-input h2 {
	font-size: 30px;
	font-family: poppins-bold;
	color: #000000;
}

.form-group .heading-input {
	margin-right: 120px;
}

.footer-content-btn {
	display: flex;
	justify-content: space-between;
	margin-top: 10px;
}

.footer-content-btn .contect h4 {
	font-size: 30px;
	font-family: poppins-bold;
	color: #000000;
}

.footer-content-btn .contect p {
	font-size: 16px;
	font-family: poppins-regular;
	color: #000000;
	margin: 3px 0;
}

.footer-content-btn .contect span {
	color: #ee2238 !important;
	font-size: 16px;
	font-family: poppins-bold;
	color: #000000;
}

.footer-content-btn .contect span a {
	color: #ee2238;
	text-decoration: none;
}

.footer-content-btn .contect {
	line-height: 1;
}

.footer-content-btn .getstartbtn {
	margin-right: 73px;
}

.footer-content-btn .getstartbtn .btn {
	border: 1px solid #ee2238;
	padding: 15px 81px;
	background-color: #ee2238;
	border-radius: 0;
}

.footer-content-btn .getstartbtn .btn {
	text-decoration: none;
	font-family: "Poppins";
	font-size: 14px;
	color: #ffff;
	font-weight: 600;
}

.footer-content-btn .getstartbtn .btn:hover {
	color: #ee2238;
}

.footer-content-btn .getstartbtn .btn:hover {
	border: 1px solid #ee2238;
	background-color: transparent;
}

.footer-content-btn .getstartbtn .btn:hover a {
	color: #ee2238;
}

.footerlink {
	margin-top: 40px;
}

.socialitemsfooter ul {
	display: flex;
	justify-content: start;
	align-items: center;
}

.socialitemsfooter ul li {
	list-style: none;
	margin: 0 12px;
}

.footerlinkend ul.footerul {
	display: flex;
}

.footerlinkend ul.footerul li {
	list-style: none;
	margin-left: 50px;
}

.footerlinkend ul.footerul li a {
	text-decoration: none;
	font-family: Poppins-semibold;
	font-size: 16px;
	color: #000000;
}

.footerlinkend ul.footerul li a:hover {
	color: #ee2238;
	transition: 0.3s;
}

.socialitemsfooter ul li a i {
	background: #ee2238;
	border-radius: 50px;
	color: #ffffff;
	font-size: 23px;
	width: 40px;
	height: 40px;
	text-align: center;
	line-height: 38px;
	padding-top: 3px;
}

.socialitemsfooter ul li a i:hover {
	background-color: #000000;
	transition: 0.2s;
	color: #ee2238;
}

.footerlink .copycontent {
	text-align: center;
	border-top: 1px solid #dbdbdb;
	padding-top: 12px;
}

.footerlink .copycontent p {
	color: #707070;
	font-size: 10px;
	font-family: Poppins-Light;
	margin-right: 139px;
}

.pre-img img {
	pointer-events: none;
	overflow-x: hidden;
	max-width: 100%;
}

.pre-img {
	overflow-x: hidden !important;
}

/* Begin of privacy section  */
.privacy-things p {
	font-size: 16px;
	color: #000000;
	font-family: "Poppins";
	font-weight: 400;
	line-height: 2;
	text-transform: capitalize;
}

.privacy-things ul li {
	font-size: 16px;
	color: #000000;
	font-family: Poppins-regular;
	line-height: 46px;
	margin-left: 30px;
	text-transform: capitalize;
	list-style: none;
}

.privacy-things h2 {
	font-size: 30px;
	color: #000000;
	font-family: "Poppins";
	font-weight: 700;
	padding: 20px 0px;
}

.privacy-things h3 {
	font-size: 30px;
	color: #000000;
	font-family: Poppins-bold;
	padding: 30px 0px;
}

.dv-para p {
	font-size: 16px;
	color: #ffffff;
	font-family: "Poppins-Regular";
	height: 270px;
	text-transform: capitalize;
}

.privacy-things ul li::before {
	content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAMCAYAAABr5z2BAAAABHNCSVQICAgIfAhkiAAAAQ1JREFUKFOVks9VwkAYxL/hIkcWGwg0YC68cAwdaAXGCsASqMBQAVABdCBH0EsqwFgABm9wcIdNhPwj+iCHfS+7b34z385Crvwiy24Q9RkoQxUuF7hG/yu+eRXAFnIL6t7FgIL46Erh6CJAZHVsojZOnE9iyvT2Y+klgMjqegT74L6nwmCbH+sojmM3TvsQPqv1yo//EbWdAQUvySEZ5CGVYi1P5vImKWzTdnwI+pkr58316sGkck2qWcG5JE4SxMum1Z0A8phCKAtz4uag39D0VPg2L7eWXuIZJKMZsXZV+B5UVV5o4RzCf8XpCHnyV8sJzNx3QvkEf+7/cs4aKeWKH4xG3a9xNyhXWjXCAdJXe1fMv1xOAAAAAElFTkSuQmCC);
	position: absolute;
	left: 16px;
}

/* End of privacy section  */

/* Begin of Service section  */
.all-box {
	background-color: #ee2238;
	padding: 30px;
	/* height: 500px; */
	transition: 0.7s;
}

.all-box .dv-img img {
	background: #fff;
	height: 110px;
	width: 120px;
	padding: 10px 15px;
}

.all-box p span {
	color: #fff !important;
}

.all-box:hover {
	background-color: #000000;
	transform: scale(1.1) !important;
	cursor: pointer;
	transition: 0.7s;
}

.all-box:hover .dv-head h3 {
	color: #ee2238 !important;
}

.all-box:hover .dv-para p {
	color: #ee2238 !important;
}

.dv-head h3 {
	font-size: 30px;
	color: #ffffff;
	font-family: Poppins-bold;
	padding: 10px 0px;
}

/* span.paramove {
  line-height: 13.8;
}
span.paramoving {
  line-height: 21.1;
} */
.dv-para p {
	font-size: 16px;
	color: #ffffff;
	font-family: "Poppins-Regular";
	height: 270px;
	margin: 0;
}

.dv-para .btn {
	padding-left: 0;
}

.dv-img1 img.img-fluid {
	margin: 6px 0px;
}

.rasani-para1.service-para ul {
	margin-left: 24px;
}

.rasani-para1.service-para h4 {
	font-size: 25px;
	color: #000000;
	font-family: Poppins-bold;
}

.rasani-para1.service-para ul li span {
	font-size: 16px;
	line-height: 37px;
	font-family: "Poppins";
	font-weight: 400;
	position: relative;
	top: -4px;
}

.rasani-para1.service-para ul li::marker {
	font-size: 23px;
}

.all-box a {
	font-size: 13px;
	color: #ffffff;
	font-family: "Poppins";
	font-weight: 600;
	text-decoration: unset;
}

section.our-service-section {
	padding: 80px 0px;
}

.dv-head {
	padding-top: 16px;
}

.ours-service {
	padding-bottom: 40px;
}

/* mobile header */
.canvas-icon {
	display: none;
}

header#mobileheader {
	position: fixed;
	right: 0;
	top: 0;
	background: #f8f8f8;
	width: 50%;
	height: 180%;
	z-index: 1;
	transform: translateX(0%) !important;
	transition: width 0.35s ease-in-out;
	display: none;
}

.ti {
	transform: translateX(100%) !important;
}

.canvas-icon {
	position: absolute;
	right: 20px;
	top: 32px;
}

.canvas-icon i.fa.fa-bars {
	font-size: 39px;
	color: black;
}

.navbar-mobile li {
	list-style: none;
	margin: 20px 0px;
}

.navbar-mobile li a {
	text-decoration: none;
	color: #ee2238;
	font-family: poppins-semibold;
	font-size: 17px;
}

.cancel svg {
	font-size: 20px;
}

.cancel i.fa.fa-window-close {
	font-size: 40px;
	margin: 10px 0px 0px 10px;
}

.lead-buyind-para h2 {
	font-size: 30px;
	color: #000000;
	font-family: Poppins-bold;
	margin-bottom: 30px;
}

.lead-buyind-para h4 {
	font-size: 30px;
	color: #000000;
	font-family: Poppins-bold;
	margin-bottom: 30px;
}

.lead-buyind-para p {
	font-size: 16px;
	line-height: 25px;
	font-family: "Poppins";
	font-weight: 400;
	margin-bottom: 30px;
	text-transform: capitalize;
}

/* End of Service section  */
@media (max-width: 1440px) {
	.heading-input {
		margin-right: unset;
	}

	.footer-content-btn .getstartbtn {
		margin-right: unset;
	}

	.socialitems {
		margin-left: -68px;
	}
}

@media (max-width: 1366px) {
	.heading-input {
		margin-right: unset;
	}

	.privacy-things h3 {
		font-size: 25px;
	}

	.privacy-things h2 {
		font-size: 30px;
	}

	.heading-input {
		margin-right: unset;
	}

	.footer-content-btn .getstartbtn {
		margin-right: unset;
	}
}

@media (max-width: 1280px) {
	.heading-input {
		margin-right: unset;
	}

	.footer-content-btn .getstartbtn {
		margin-right: unset;
	}

	.footerlinkend ul.footerul li {
		margin-left: 40px;
	}

	.why-content {
		margin-bottom: 20px;
	}

	.all-box {
		margin: 22px 0;
	}

	section.our-service-section {
		padding: 20px 0px;
	}

	/* footer#footerdown {
		height: auto;
		padding: 0 0 140px 0;
	} */
	.footerlink .copycontent p {
		margin: 0;
	}
}

@media (max-width: 1024px) {
	.footerlinkend ul.footerul {
		display: flex;
		justify-content: center;
	}

	.canvas-icon {
		display: block;
	}

	.nav-bar {
		display: none;
	}

	.img-content img {
		width: 90%;
	}

	.thirdsec-content {
		margin: -10px 40px 0 0;
	}

	section.thirdsec .thirdsec-content .heading-para p {
		font-size: 12px;
	}

	.Banner .banner-content .heading-para p {
		font-size: 13px;
	}

	.Banner .banner-content .heading-para h2 {
		font-size: 55px;
	}

	.dv-para p {
		font-size: 14px;
	}

	.Banner .banner-img-erapper .manycheckbox .digital {
		right: -30px;
	}

	.heading-input {
		margin-right: unset;
	}

	.footer-content-btn .getstartbtn {
		margin-right: unset;
	}

	.footer-content-btn .getstartbtn .btn {
		padding: 15px 61px;
	}

	.footer-content-btn .contect h4 {
		font-size: 24px;
	}

	.footerlinkend ul.footerul li a {
		font-size: 12px;
	}

	.content-para h4 {
		font-size: 16px;
	}

	.content-para p {
		font-size: 11px;
	}

	.para-social p {
		font-size: 18px;
	}

	.para-social {
		padding-top: 20px;
		padding-left: 78px;
	}

	.socialitems1 {
		padding: 26px 0px;
		margin-left: 120px;
	}

	.all-box {
		margin: 10px 0;
	}

	.footerlinkend ul.footerul li {
		margin-left: 10px;
	}

	.footerlink .copycontent p {
		margin-right: 0;
	}

	.img-content {
		margin-bottom: 40px;
	}

	.dv-para p {
		font-size: 12px;
	}

	.para-social p {
		font-size: 13px;
	}

	.socialitems1 {
		padding: 26px 0px;
		margin-left: -10px;
	}

	.para-social {
		padding-top: 27px;
		padding-left: 72px;
	}

	.all-box {
		margin-top: 20px;
	}

	.all-box {
		height: 430px;
	}

	.dv-para p {
		height: 190px;
	}

	/* footer#footerdown {
		height: 34vh;
	} */
	.footerlinkend ul.footerul li a {
		font-size: 11px;
	}

	.lastfooter {
		margin-bottom: 10px;
	}

	section.our-service-section {
		padding: 30px 0px;
	}
}

@media (max-width: 912px) {
	.footerlinkend ul.footerul li {
		margin-left: 10px;
	}

	.footerlink .copycontent p {
		margin-right: 0;
	}

	.img-content {
		margin-bottom: 40px;
	}

	.dv-para p {
		font-size: 12px;
	}

	.para-social p {
		font-size: 13px;
	}

	.socialitems1 {
		padding: 26px 0px;
		margin-left: -10px;
	}

	.para-social {
		padding-top: 27px;
		padding-left: 72px;
	}

	.all-box {
		margin-top: 20px;
	}

	.all-box {
		height: 430px;
	}

	.dv-para p {
		height: 190px;
	}

	/* footer#footerdown {
		height: auto;
		padding: 0 0 710px 0;
	} */
	.footerlinkend ul.footerul li a {
		font-size: 11px;
	}

	.lastfooter {
		margin-bottom: 10px;
	}

	section.our-service-section {
		padding: 30px 0px;
	}

	.footer-content-btn {
		margin-bottom: 30px;
	}
}

@media (max-width: 820px) {
	.footerlinkend ul.footerul li {
		margin-left: 15px;
	}

	.footer-content-btn {
		margin-bottom: 30px;
	}

	.nav-bar-mobile ul {
		padding: 0;
	}

	.footerlink .copycontent p {
		margin-right: 0;
	}

	.img-content {
		margin-bottom: 40px;
	}

	.dv-para p {
		font-size: 12px;
	}

	.para-social p {
		font-size: 13px;
	}

	.socialitems1 {
		padding: 26px 0px;
		margin-left: -10px;
	}

	.para-social {
		padding-top: 27px;
		padding-left: 72px;
	}

	.all-box {
		margin-top: 20px;
	}

	.all-box {
		height: 430px;
	}

	.dv-para p {
		height: 190px;
	}

	/* footer#footerdown {
		height: auto;
		padding: 0 0 520px 0;
	} */
	.footerlinkend ul.footerul li a {
		font-size: 11px;
	}

	.lastfooter {
		margin-bottom: 10px;
	}

	section.our-service-section {
		padding: 30px 0px;
	}
}

@media (max-width: 768px) {
	.all-box {
		margin-top: 20px;
	}

	.all-box {
		height: 430px;
	}

	.dv-para p {
		height: 190px;
	}

	/* footer#footerdown {
		height: auto;
		padding: 0 0 370px 0;
	} */
	.footerlinkend ul.footerul li a {
		font-size: 11px;
	}

	.lastfooter {
		margin-bottom: 10px;
	}

	section.our-service-section {
		padding: 30px 0px;
	}
}

@media (max-width: 575px) {
	.Banner .banner-img-erapper .manycheckbox .digital {
		right: 0px;
	}

	.Banner .banner-img-erapper .manycheckbox .data {
		right: 0px;
		top: -198px;
	}

	.footer-content-btn .getstartbtn .btn {
		padding: 15px 51px;
		margin-right: unset;
	}

	.thirdsec-content {
		margin: 30px 40px 0 0;
	}

	.socialitems1 {
		padding: 26px 0px;
		margin-left: 230px;
	}

	.para-social {
		padding-top: 20px;
		padding-left: 121px;
	}

	.socialitemsfooter ul {
		display: flex;
		justify-content: center;
	}

	.footerlinkend ul.footerul {
		display: flex;
		justify-content: center;
	}

	.logo-mainfooter {
		text-align: center;
	}

	.leadmargin {
		margin-top: 50px;
		margin-bottom: -20px;
	}

	.dv-para p {
		height: 160px;
	}

	.all-box {
		height: 420px;
	}

	/* footer#footerdown {
		height: auto;
		padding: 0 0 10px 0;
	} */
	.footer-form {
		margin-left: 0;
	}

	.socialitems1 ul li {
		margin-left: 13px;
		list-style: none;
	}

	header#mobileheader {
		width: 60%;
	}

	.socialitems1 ul {
		display: flex;
		margin: 6px 28px;
	}

	.create-account-sec .form-btn {
		text-align: center;
		margin-top: 10px;
	}
}

@media (max-width: 540px) {
	.footer-content-btn {
		margin-bottom: 31px;
	}

	.footer-form {
		margin-left: 0;
	}

	.footer-content-btn .getstartbtn .btn {
		padding: 15px 51px;
		margin-right: unset;
	}

	.leadmargin {
		margin-top: 50px;
		margin-bottom: -20px;
		padding-bottom: 20px;
	}

	header#mobileheader {
		width: 60%;
	}

	.privacy-things ul li {
		font-size: 14px;
		font-family: "Poppins-regular";
		line-height: 39px;
	}

	.Banner .banner-img-erapper .manycheckbox .digital {
		right: 0px;
	}

	.Banner .banner-img-erapper .manycheckbox .data {
		right: 0px;
		top: -198px;
	}

	.thirdsec-content {
		margin: 30px 40px 0 0;
	}

	.socialitems1 {
		padding: 26px 0px;
		margin-left: -20px;
	}

	.para-social {
		padding-top: 29px;
		padding-left: 41px;
	}

	.para-social p {
		font-size: 11px;
		text-align: center;
	}

	.socialitems1 ul li a i {
		font-size: 17px;
		color: #ffffff;
	}

	.socialitems {
		margin-left: -22px;
	}

	.socialitemsfooter ul {
		display: flex;
		justify-content: center;
	}

	.footerlinkend ul.footerul {
		display: flex;
		justify-content: center;
	}

	.logo-mainfooter {
		text-align: center;
	}

	.leadmargin {
		margin-top: 50px;
		margin-bottom: -20px;
	}

	.dv-para p {
		height: 160px;
	}

	.all-box {
		height: 420px;
	}

	/* footer#footerdown {
		height: auto;
		padding: 0 0 60px 0;
	} */
}

@media (max-width: 480px) {
	header#header-top .nav-bar .navbar li a {
		font-size: 10px;
	}

	.Banner .banner-img-erapper .manycheckbox .digital {
		right: -14px;
		top: -520px;
	}

	.img-footer img {
		height: 100%;
		object-fit: cover;
	}

	.Banner .banner-img-erapper .manycheckbox .data {
		position: absolute;
		right: 10px;
		top: -198px;
	}

	.navbar-mobile li a {
		font-size: 11px;
	}

	section.thirdsec .thirdsec-content .about-content {
		margin-top: 40px;
	}

	.para-social p {
		font-size: 13px;
	}

	.footer-content-btn .contect h4 {
		font-size: 20px;
	}

	.socialitemsfooter ul {
		display: flex;
		justify-content: center;
	}

	.footerlinkend ul.footerul li a {
		font-size: 13px;
	}
}

@media (max-width: 414px) {
	.socialitemsfooter ul {
		justify-content: space-evenly;
	}

	.footerlinkend ul.footerul {
		justify-content: space-evenly;
	}

	.footerlinkend ul.footerul li {
		margin: 0;
	}

	.Banner .banner-img-erapper .manycheckbox .digital {
		right: -11px !important;
		top: -576px !important;
	}

	.navbar-mobile li a {
		font-size: 12px;
	}

	.thirdsec-content {
		margin: 0px 0px 0 0;
	}

	section.thirdsec .thirdsec-content .banner-btn {
		margin-top: 41px;
		margin-bottom: 40px;
	}

	.socialitems {
		margin-left: -68px;
		padding: 0;
	}

	.para-social p {
		font-size: 8px;
	}

	.socialitems1 ul li a i {
		font-size: 13px;
		color: #ffffff;
	}

	.socialitems1 {
		padding: 25px 0px;
		margin-left: -30px;
	}

	.heading-input {
		margin-right: unset;
	}

	.para-social {
		padding-top: 33px;
		padding-left: 51px;
	}

	/* footer#footerdown {
		height: auto;
		padding: 20px 0 246px;
	} */
	/* .footerlinkend {
  margin-right: 40px;
} */
	.lastfooter {
		margin-bottom: -20px;
	}

	header#header-top .nav-bar .navbar li a {
		font-size: 10px;
	}

	.Banner .banner-content .heading-para h2 {
		font-size: 55px;
	}

	.Banner .banner-content .heading-para p {
		font-size: 12px;
	}

	.Banner .banner-img-erapper .manycheckbox .live {
		left: -33px;
		top: -315px;
	}

	.Banner .banner-img-erapper .manycheckbox .data {
		right: -13px;
		top: -198px;
	}

	span.paramoving {
		line-height: 0.1;
	}

	section.our-service-section {
		padding: 0px 0px;
	}

	.banner-content {
		margin-bottom: 40px;
	}

	.all-box {
		margin-bottom: 20px;
	}

	.img-content img {
		width: 100%;
	}

	section.thirdsec .thirdsec-content .heading-para p {
		font-size: 12px;
	}

	.para-social p {
		font-size: 15px;
		padding: -3px 4px;
	}

	.img-footer img {
		height: 100%;
		object-fit: cover;
	}

	.heading-input h2 {
		font-size: 14px;
	}

	.footer-content-btn .contect h4 {
		font-size: 20px;
		line-height: 0.6;
	}

	.footer-content-btn .getstartbtn {
		margin: 0;
	}

	.footer-content-btn .getstartbtn .btn {
		padding: 15px 51px;
	}

	.footerlinkend ul.footerul li a {
		font-size: 13px;
	}
}

@media (max-width: 393px) {
	/* footer#footerdown {
		height: auto;
		padding: 0px 0 190px 0;
	} */
}

@media (max-width: 375px) {
	.Banner .banner-img-erapper .manycheckbox .digital {
		right: -11px !important;
		top: -498px !important;
	}

	.navbar-mobile li a {
		font-size: 12px;
	}

	.thirdsec-content {
		margin: 0px 0px 0 0;
	}

	section.thirdsec .thirdsec-content .banner-btn {
		margin-top: 41px;
		margin-bottom: 40px;
	}

	.socialitems {
		margin-left: -68px;
		padding: 0;
	}

	.para-social p {
		font-size: 8px;
	}

	.socialitems1 ul li a i {
		font-size: 13px;
		color: #ffffff;
	}

	.socialitems1 {
		padding: 25px 0px;
		margin-left: -30px;
	}

	.heading-input {
		margin-right: unset;
	}

	.para-social {
		padding-top: 33px;
		padding-left: 51px;
	}

	/* .footerlinkend {
  margin-right: 40px;
} */
	.lastfooter {
		margin-bottom: -20px;
	}

	header#header-top .nav-bar .navbar li a {
		font-size: 10px;
	}

	.Banner .banner-content .heading-para h2 {
		font-size: 55px;
	}

	.Banner .banner-content .heading-para p {
		font-size: 12px;
	}

	.Banner .banner-img-erapper .manycheckbox .live {
		left: -33px;
		top: -315px;
	}

	.Banner .banner-img-erapper .manycheckbox .data {
		right: -13px;
		top: -198px;
	}

	span.paramoving {
		line-height: 0.1;
	}

	section.our-service-section {
		padding: 0px 0px;
	}

	.banner-content {
		margin-bottom: 40px;
	}

	.all-box {
		margin-bottom: 20px;
	}

	.img-content img {
		width: 100%;
	}

	section.thirdsec .thirdsec-content .heading-para p {
		font-size: 12px;
	}

	.para-social p {
		font-size: 13px;
		padding: 0 13px;
	}

	.img-footer img {
		height: 100%;
		object-fit: cover;
	}

	.heading-input h2 {
		font-size: 14px;
	}

	.footer-content-btn .contect h4 {
		font-size: 20px;
		line-height: 0.6;
	}

	.footer-content-btn .getstartbtn {
		margin: 0;
	}

	.footer-content-btn .getstartbtn .btn {
		padding: 15px 51px;
		margin-right: unset;
	}

	.footerlinkend ul.footerul li a {
		font-size: 12px;
	}

	/* footer#footerdown {
		height: auto;
		padding: 20px 0 14px;
	} */
}

@media (max-width: 360px) {

	/* footer#footerdown {
		height: auto;
		padding: 0 0 90px 0;
	} */
	.para-social p {
		font-size: 13px;
		padding: 0 9px;
	}
}

@media (max-width: 320px) {
	.logo-mainfooter {
		margin-left: 0px;
	}

	/* footer#footerdown {
		height: auto;
		padding: 20px 0 19px 0;
	} */
	.para-social p {
		font-size: 11px;
		padding: 0 9px;
	}

	.footer-content-btn {
		margin-bottom: 21px;
	}

	.footer-content-btn .contect h4 {
		font-size: 18px;
		line-height: 0.6;
	}

	.footer-content-btn .contect span {
		font-size: 14px;
	}

	header#mobileheader {
		width: 60%;
	}

	.socialitems1 ul li {
		margin-left: 12px;
		list-style: none;
	}

	.create-account-sec .form-btn {
		text-align: center;
	}

	.lead-buyind-para h2 {
		font-size: 21px !important;
	}

	.footerlinkend ul.footerul li a {
		font-size: 10px;
	}

	.lead-buyind-para h4 {
		font-size: 21px !important;
	}

	.socialitems1 ul {
		display: flex;
		margin: 3px 29px;
	}

	.https {
		overflow-wrap: break-word;
	}

	.right-buyer {
		margin-bottom: 50px;
	}

	.get-started-text h2 {
		font-size: 18px;
	}

	section.how-rasani-sec {
		padding: 0px 0px 0px 0px;
	}

	.banner-content {
		margin-bottom: 90px;
	}

	.footer-content-btn .contect p {
		font-size: 15px;
		margin-bottom: 5px;
	}

	.footer-content-btn .getstartbtn .btn {
		padding: 15px 31px;
		/* text-align: end; */
		margin-right: unset;
	}
}