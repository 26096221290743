.create {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.create-account-sec .form-group input {
	width: 100%;
	background-color: #f5f5f5;
	border: none;
	padding: 12px 18px;
	outline: none;
}
.create-account-sec .form-group span.errorMessage p {
	font-size: 12px;
	color: red;
}
.create-account-sec .form-group .SetErrorshow {
	border: 1px solid red;
}
.create-account-sec .form-btn button {
	background-color: #ee2238;
	padding: 11px 80px;
	border: none;
	font-size: 16px;
	color: #ffffff;
	font-family: "Poppins";
	font-weight: 600;
}
.create-account-sec .form-btn button:hover {
	border: 1px solid #ee2238;
	background-color: transparent;
	color: #ee2238;
}
.create-account-sec .form-btn1 button {
	background-color: #ee2238;
	padding: 16px 41px;
	border: none;
	font-size: 16px;
	color: #ffffff;
	font-family: "Poppins";
	font-weight: 600;
}
.create-account-sec .form-btn1 {
	text-align: right;
}

.create-account-sec .form-btn {
	text-align: right;
}

.create-account-sec .line-head p {
	font-size: 16px;
	color: #000000;
	font-family: "Poppins";
	font-weight: 400;
}

.create-text p {
	font-size: 16px;
	color: #000000;
	font-family: "Poppins";
	font-weight: 400;
}

.create-text p a {
	color: #ee2238;
}

.create {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-bottom: 20px;
}
.create-all-para p {
	font-size: 16px;
	color: #000000;
	font-family: "Poppins";
	font-weight: 400;
}

section.create-account-para {
	padding-top: 90px;
}
@media (max-width: 575px) {
	.create {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-bottom: 20px;
		line-height: 1.4;
		flex-direction: column;
	}
	section.aboutus-sec {
		padding: 30px 0px 30px 0px;
	}
	.create p {
		margin: 1px 0;
		margin-bottom: 9px;
	}
	section.create-account-para {
		padding-top: 54px;
	}
	.create-text {
		text-align: start;
		width: 100%;
	}
	.create-account-sec .form-btn1 {
		text-align: center;
		margin-top: 10px;
	}
}
